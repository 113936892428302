<template>
  <div class="privacy">
    <public-header :button="{url: 'login', text: $t('landing.merchant_signin')}"/>

    <div class="container">
      <h2><strong>Privacy &amp; Security at LifeS</strong></h2>
      <p><strong>Date: 21/12/2021</strong><strong><br /></strong><strong><br /></strong><strong>Our company at LifeS Group Limited places high importance on user&rsquo;s data privacy.</strong><strong>LifeS Group Limited and its affiliates (&ldquo;LifeS&rdquo;, &ldquo;we&rdquo;, &ldquo;us&rdquo;, &ldquo;our&rdquo; or the &ldquo;Company&rdquo;) are committed to protecting the privacy of individuals and merchants who visit and use our website/ portal, Individuals who access and/or use our software, including our associated mobile applications owned and controlled by LifeS Group Limited. Our company may update or change this policy and changes will be posted on our website.</strong></p>
      <p><br />This Privacy and Security Policy describes LifeS privacy and security policy practices in relation to your access and uses of Platform and Solutions and its products and Mobile Apps (collectively, the &ldquo;Services&rdquo;), your use and access to our website and your choices regarding use, access, correction and deletion of your personal information.<br /><br />If you do not agree with the terms, do not access or use the services and websites or any other aspect of LifeS Group Limited&rsquo;s business. We hope you can spend some time to read the below policy to address any questions or concerns you have.</p>
      <p><br /><br /></p>
      <p><strong>Applicability of this policy</strong><br /><br /></p>
      <ol>
        <li>This policy covers how our company treats personal data collected, including data related to the use of our website and company services. Personal data is defined as personally identifiable information such as your name, address, email address or phone number, and information which are not publicly available.</li>
      </ol>
      <ol start="2">
        <li>This policy has no legal fore on companies which are not owned or affiliated by our company, is not applicable to any person who are not hired or managed by our company.<br /><br /><br /></li>
      </ol>
      <p><strong>Collection and usage of Personal data</strong></p>
      <ol start="3">
        <li>Your personal data will be collected during
        <p>a. registration of opening an account at our company, either through a User of the LifeS Services App, or as a Merchant on the LifeS Website</p>
        <p>b. use of our products or services</p>
        <p>c. browsing the website or app including our business partners</p>
        </li>
      </ol>
      <ol start="4">
        <li><br/>
          <p>a. As a LifeS User on the App, during registration, our company will ask for your mobile number to register. Upon account setup other information such as email and name will be required for account setup. Other information such as date of birth and gender will also be optional for entry once the account is created.</p>
          <p>b. As a LifeS Merchant on the Website, during registration, our company will ask you for your name, email address and phone number. Other information fields will also be requested for such as venue opening/ closing times, venue contacts, venue services and goods on offer. After the merchant venue has been approved, the merchant can submit further edits to LifeS for changes to their business circumstances and operating model.</p>
        </li>
      </ol>
      <ol start="5">
        <li>Our company will receive and record server data automatically from your browser, including information and browsing record from internet protocol address (IP) and our company cookies.</li>
      </ol>
      <ol start="6">
        <li>Our company will use the information for the follow purposes
        <p>a. to provide you with products and services</p>
        <p>b. to improve our services</p>
        <p>c. for contact purposes</p>
        <p>d. for research and development purposes</p>
        <p></p>
        </li>
      </ol>
      <ol start="7">
        <li>
          <p>For more information you may contact <a href="mailto:info@lifes.hk">info@lifes.hk</a><br/></p>
          <p>Phone / WhatsApp us on 5932 8395.</p>
        </li>
      </ol>
      <p><strong>Your Privacy Rights</strong></p>
      <ol start="8">
        <li>In accordance with the terms of the Personal Data (Privacy) Ordinance (&ldquo;the Ordinance&rdquo;) and subject to exemptions specified in the Ordinance, any customer:
          <p>a. has the right to check whether our company holds personal data about him or her and has a right to receive copies of such data;</p>
          <p>b. has the right to require our company to correct any personal data relating to him or her which is inaccurate;</p>
          <p>c. has the right to ascertain our company policies and practices in relation to personal data and to be informed of the kind of personal data held by our company.</p>
          <p>d. In accordance with the terms of the Ordinance, our company has the right to charge a reasonable fee for processing a data access request.</p>
          <p>e. Requests for access to personal data, correction of personal data, information relating to the kind of data held and to stop receiving or re-start receiving direct marketing, please identify yourself with your name, contact number, e-mail and registered account detail and e-mail to our company.</p>
          <p>f. For more information you may contact <a href="mailto:info@lifes.hk">info@lifes.hk</a></p>
        </li>
      </ol>
      <p><strong>Disclosure and Sharing of Personal Data</strong><br /><br /></p>
      <ol start="9">
        <li>Our company would not rent, sell or disclose your personal data with other people or non affiliated companies unless our company get your permission, or provide you with your requested products and services or in the following circumstances:
          <p>a. The personal data might be shared to some cooperation partners which have good faith or have signed confidential agreement with our company. Those companies&nbsp;might use your personal data to inform you the product and service about our company or our partners. However, these companies have nondependent rights to share your personal data.</p>
          <p>b. Our company respond to subpoenas, court cries or legal procedures or in response to formulate or exercising our legal rights, or defend the claims</p>
          <p>c. Our company thinks it is necessary to share relevant information to help investigate, prevent or take action on illegal activities, suspected fraud, or events involving or threaten to personal safety, and to prevent breaking terms of use of e-print or illegal behaviour. In receiving relevant aspects, the demand is according to the personal information (privacy) ordinance under article 58.</p>
          <p>d. If our company were acquired or merged by other companies, our company will hand over your relevant information. In this case, our company will inform you before the handover, and will be constrained by another piece of privacy policy.</p>
          <p>e. Our company sends out targeted advertisements according to the personal data. Advertisers (including advertising agencies) may assume all, who have interaction with the advertisement, watch or click the target advertisements meet the definition of target customers - for example, female between 18 and 24 years old in a region.</p>
          <p>f. Our company would not provide any users&rsquo; personal data to advertisers if you interact in or watch the target advertisement. However, by watching or interacting in the advertisement, advertisers may assume you meet the definition of target customers.</p>
          <p>g. Our company&rsquo;s advertisers included financial service providers (such as banks, insurance agents, stock brokers and Mortgage lenders) and non-financial&nbsp;companies (such as shops, airline and software companies)</p>
          <p>h. According to the setting, our company will display your personal data in your online account. Meanwhile, our company may also display your account ID or username in some occasions (such as forum or lucky draws)</p>
        </li>
      </ol>
      <p><strong>Transfer of Personal Data</strong><br /><br /></p>
      <ol start="10">
        <li>Our company may store, process and / or transfer the personal data to cloud storage software or business partners of our company that selected carefully for data management and business purposes, especially for outsourcing institutions to handle the data.<br /><br /></li>
      </ol>
      <p><strong>Cookie</strong><br /><br /></p>
      <ol start="11">
        <li>Our company would access to your PC to set and get our company cookies.<br /><br /></li>
        <li>Our company would allow advertising agencies that launch advertisements in our website to access to your PC to set and get cookies. Privacy policy would be referred to those companies&rsquo; own privacy protection policy to&nbsp;</li>
      </ol>
      <p>use the cookies.<br /><br /></p>
      <ol start="13">
        <li>When our company is undertaking jobs that related to our products or services, our company would use web beacons to extract and use the cookies.</li>
      </ol>
      <p><strong>Rights of modify and delete individual account data and preferences setting</strong><br /><br /></p>
      <ol start="14">
        <li>User can update his / her account data anytime, including preferences setting on the our website or through the app.<br /><br /></li>
        <li>Our company reserves the rights to send you information such as service notifications, administration notice or company newsletter, user do not have right to reject such information.<br /><br /></li>
        <li>Merchant Users can delete his / her account in our company web-site, and App users can delete his/ her account through the App.</li>
      </ol>
      <p><strong>Security of Personal Data</strong><br /><br /></p>
      <ol start="17">
        <li>Our company thinks there are a reasonably needs to contact you, to offer you products or services or our company employees have reasonable needs at work to access your personal data. Our company has a set of substantial, electronic procedural security measures to protect your personal data.</li>
      </ol>
      <ol start="18">
        <li>Our company will never ask or request you to provide us your password. All password resets are done through the website or app. Please do not provide or share your password to anyone through email or phone call. Only use your password to login to your account. <br /><br /></li>
        <li>If in doubt of password resets or issues please contact <a href="mailto:info@lifes.hk">info@lifes.hk</a></li>
      </ol>
    </div>
    <public-footer/>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import publicHeader from '@/components/publicHeader/publicHeader'
import footer from '@/components/footer/footer'

export default {
  name: 'Privacy',
  components: {
    publicHeader,
    'public-footer': footer
  },
  data () {
    return {
    }
  },
  computed: {
    ...mapGetters({
      isAuthed: 'auth/isAuthed',
      me: 'auth/me'
    })
  },
  mounted () {
  },
  methods: {
  }
}
</script>

<style scoped lang="scss">
@import "style";
</style>
