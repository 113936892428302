<template>
  <div class="footer">
    <div class="footer__footer">
      <div class="container">
        <div class="footer__footer-cont">
          <div class="footer__footer-left">
            <img src="/images/landing/logo-small-w.png" class="footer__footer-logo"/>
            <p>{{$t('landing.improving_life')}}</p>
            <div class="flex align-center justify-start">
              <a href="https://www.youtube.com/channel/UCkaR302D-6tmsQrFJUNYcoQ" target="_blank"><img src="/images/landing/youtube.png" class="footer__footer-social"/></a>
              <a href="https://www.facebook.com/profile.php?id=100075710576844" target="_blank"><img src="/images/landing/facebook.png" class="footer__footer-social"/></a>
              <a href="https://twitter.com/LifeS_Connect" target="_blank"><img src="/images/landing/twitter.png" class="footer__footer-social"/></a>
              <a href="https://instagram.com/lifes_hong_kong?igshid=YmMyMTA2M2Y=" target="_blank"><img src="/images/landing/instagram.png" class="footer__footer-social"/></a>
            </div>
          </div>
          <div class="footer__footer-menu">
            <h4>{{$t('landing.footer_m1')}}</h4>
            <router-link :to="{path: '/info', hash: '#lifes'}">{{$t('landing.footer_m1_1')}}</router-link>
            <router-link :to="{path: '/info', hash: '#lifes'}">{{$t('landing.footer_m1_2')}}</router-link>
            <router-link :to="{path: '/info', hash: '#lifes'}">{{$t('landing.footer_m1_3')}}</router-link>
            <router-link :to="{path: '/info', hash: '#rewards'}">{{$t('landing.footer_m1_4')}}</router-link>
          </div>
          <div class="footer__footer-menu">
            <h4>{{$t('landing.footer_m2')}}</h4>
            <router-link :to="{path: '/info', hash: '#help'}">{{$t('landing.footer_m2_1')}}</router-link>
            <router-link :to="{path: '/info', hash: '#help'}">{{$t('landing.footer_m2_2')}}</router-link>
            <router-link :to="{path: '/faq'}">{{$t('landing.footer_m2_3')}}</router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="footer__copy">
      <div class="container">
        <div class="footer__copy-cont">
          <p>{{$t('landing.copy')}}</p>
          <div class="footer__copy-menu">
            <router-link to="/terms">{{$t('landing.copym_1')}}</router-link>
            <router-link to="/privacy">{{$t('landing.copym_2')}}</router-link>
            <!-- <router-link to="/privacy">{{$t('landing.copym_3')}}</router-link> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Footer',
  props: [
  ],
  data () {
    return {
    }
  },
  computed: {
  },
  methods: {
  }
}
</script>

<style scoped lang="scss">
  @import "style";
</style>
